import { useState, useEffect} from "react";
import firebase from "./components/firebase";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
	Grid,
	Container,
	Dialog,
	Button,
	DialogActions,
	Divider,
} from "@material-ui/core";
import { useHistory} from "react-router-dom";

import "./App.css";
import { Song, Info } from "./components/interface";
import Songs from "./components/songs";
import SimpleBackdrop from "./components/simpleBackdrop"
import Profile from "./components/profile"


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		paper: {
			height: 140,
			width: 100,
		},
		control: {
			padding: theme.spacing(2),
		},
		name: {
			color: "#474747",
			fontFamily: "Roboto, sans-serif",
			// font-style: normal;
			fontWeight: "bold",
			fontSize: "33px",
			textAlign: "center",
		},
		bioText: {
			color: "#474747",
			fontFamily: "Raleway",
			// fontStyle: normal;
			fontWeight: 300,
			fontSize: "23px",
			textAlign: "center",
		},
		subText: {
			color: "#474747",
			fontFamily: "Raleway",
			// fontStyle: normal;
			fontWeight: 300,
			fontSize: "16px",
			textAlign: "center",
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: "#fff",
		},
		divider: {
			width: "inherit",
			marginTop: "30px",
			marginBottom: "30px",
		}
	})
);

export default function App() {
	const classes = useStyles();
	const db = firebase.firestore();
	

	const [info, setInfo] = useState<Info>();
	const [songs, setSongs] = useState<Song[]>();

	const [contactOpen, setContactOpen] = useState(false);
	const [openBackdrop, setOpenBackdrop] = useState(true);

	useEffect(() => {
		getData();
	}, []);

	async function getData() {
		try {
			const infoDocP = db.collection("public").doc("info").get();
			const songsDocP = db.collection("public").doc("songs").get();
			const [infoinfoDoc, songsDoc] = await Promise.all([infoDocP, songsDocP]);

			if (infoinfoDoc.exists) {
				const i = infoinfoDoc.data() as Info;

				if (i.leapUrl) {
					// console.log("going to leap url");
					//TODO: leap url redirect not working
					// window.open(i.leapUrl, "_blank")
				}

				setInfo(i);
			}

			if (songsDoc.exists) {
				const doc = songsDoc.data();

				if (doc) {
					const songs = doc["songs"] as Song[];

					//TODO: Sort songs with decending date
					setSongs(songs);
				}
			}
		} catch (error) {
			console.error("Error getting data", error);
		}

		setOpenBackdrop(false);
	}

	function Header() {
		if (!info) return null;
		return (
			<Grid container item xs={8} justify="center">
				<img
					src={info.header_image_url}
					alt="header"
					style={{ maxWidth: "inherit" }}
				/>
			</Grid>
		);
	}

	function Buttons({ info }: { info?: Info }) {
		console.log(`info in buttons: ${JSON.stringify(info)}`);
		if (!info || !info.buttons) return null;
		console.log("got buttons");
		const buttons = info.buttons.sort((a, b) => {
			if (a.pos < b.pos) return -1;
			return 1;
		});

		return (
			<Grid item xs={12}>
				{buttons.map((b) => {
					return (
						<Button
							variant="contained"
							color="primary"
							style={{ color: "white", backgroundColor: "#0062FF" }}
							onClick={() => {
								window.open(b.url, "_blank");
							}}
						>
							{b.title}
						</Button>
					);
				})}
			</Grid>
		);
	}

	function contactOpenFunc(){
		setContactOpen(true)
	}

	function Contact() {
		const url = `https://us4.list-manage.com/contact-form?u=ed6adcc5c816e1973b80b059a&form_id=fa9aa24278306bd349eb75dff9bc9f22`;
		return (
			<Dialog
				open={contactOpen}
				onClose={() => setContactOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
				{/* <DialogContent> */}
				<iframe src={url} style={{ height: "700px", width: "600px" }} />
				{/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
				{/* </DialogContent> */}
				<DialogActions>
					<Button onClick={() => setContactOpen(false)} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function closeBackdrop(){
		setOpenBackdrop(false)
	}

	if (!info) {
		return <SimpleBackdrop open={openBackdrop} closeBackdrop={closeBackdrop} />;
	}

	return (
		<>
			{/* <Header /> */}
			<Container>
				<Grid
					container
					className={classes.root}
					spacing={4}
					direction="column"
					justify="center"
					alignItems="center"
					style={{ marginTop: "30px" }}
				>
					<Contact />

					{/* <Header/> */}

					<Profile info={info} setContactOpen={contactOpenFunc}/>

					<Divider className={classes.divider}/>

					<Songs songs={songs} />

					<br />
					<br />
					<br />
					<br />
					<br />
				</Grid>
			</Container>
		</>
	);
}



