import { Grid, Card, CardMedia } from "@material-ui/core";

import { Song } from "../interface";
import twitterImg from "../../assets/twitter.png";
import soundcloudImg from "../../assets/soundcloud.png";
import spotifyImg from "../../assets/spotify.png";
import instagramImg from "../../assets/instagram.png";
import youtubeImg from "../../assets/youtube.png";
import itunesImg from "../../assets/itunes.png";

export default function SongCard({ song, index }: { song: Song; index: number }) {
	return (
		<Grid key={index} item container direction="column" xs={12} sm={3}>
			<Card>
				<CardMedia>
					<div
						dangerouslySetInnerHTML={{ __html: song.embed }}
						style={{ textAlign: "center" }}
					/>
				</CardMedia>

				<Grid
					container
					direction="row"
					spacing={3}
					justify="center"
					alignItems="center"
					xs={12}
				>
					<SpotifyLink spotify={song.links.spotify} />
					<ItunesLink itunes={song.links.appleMusic} />
					<YoutubeLink youtube={song.links.youtube} />
					<SoundcloudLink soundcloud={song.links.soundcloud} />
				</Grid>
			</Card>
		</Grid>
	);
}

function SpotifyLink({ spotify }: { spotify?: string }) {
	if (!spotify) return null;

	return (
		<Grid item>
			<a href={spotify} style={{ cursor: "pointer", pointerEvents: "auto" }}>
				{" "}
				<img src={spotifyImg} alt="spotify" style={{ height: "23px" }} />
			</a>
		</Grid>
	);
}

function ItunesLink({ itunes }: { itunes?: string }) {
	if (!itunes) return null;

	return (
		<Grid item>
			<a href={itunes}>
				{" "}
				<img src={itunesImg} alt="itunes" style={{ height: "23px" }} />
			</a>
		</Grid>
	);
}

function YoutubeLink({ youtube }: { youtube?: string }) {
	if (!youtube) return null;

	return (
		<Grid item>
			<a href={youtube}>
				{" "}
				<img src={youtubeImg} alt="youtube" style={{ height: "23px" }} />
			</a>
		</Grid>
	);
}

function SoundcloudLink({ soundcloud }: { soundcloud?: string }) {
	if (!soundcloud) return null;

	return (
		<Grid item>
			<a href={soundcloud}>
				{" "}
				<img src={soundcloudImg} alt="soundcloud" style={{ height: "23px" }} />
			</a>
		</Grid>
	);
}
