import * as firebase from 'firebase';

const config = {
    apiKey: "AIzaSyAS24l8lImCsRJH6jnx7JSHTLRluUW7IdI",
    authDomain: "aziz-akgul.firebaseapp.com",
    projectId: "aziz-akgul",
    storageBucket: "aziz-akgul.appspot.com",
    messagingSenderId: "1014520887442",
    appId: "1:1014520887442:web:dd50b0ca92c61ab6408e4b",
    measurementId: "G-19FCZN1KDY"
}

export default !firebase.default.apps.length ? firebase.default.initializeApp(config) : firebase.default.app();