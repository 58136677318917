
import {
	Grid,	
	Typography,	Avatar
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import {Info} from "./interface"
import twitter from "../assets/twitter.png";
import soundcloud from "../assets/soundcloud.png";
import spotify from "../assets/spotify.png";
import instagram from "../assets/instagram.png";
import youtube from "../assets/youtube.png";
import itunes from "../assets/itunes.png";
import email from "../assets/email.png";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		paper: {
			height: 140,
			width: 100,
		},
		control: {
			padding: theme.spacing(2),
		},
		name: {
			color: "#474747",
			fontFamily: "Roboto, sans-serif",
			// font-style: normal;
			fontWeight: "bold",
			fontSize: "33px",
			textAlign: "center",
		},
		bioText: {
			color: "#474747",
			fontFamily: "Raleway",
			// fontStyle: normal;
			fontWeight: 300,
			fontSize: "23px",
			textAlign: "center",
		},
		subText: {
			color: "#474747",
			fontFamily: "Raleway",
			// fontStyle: normal;
			fontWeight: 300,
			fontSize: "16px",
			textAlign: "center",
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: "#fff",
		},
	})
);

export default function Profile({info, setContactOpen}:{info:Info, setContactOpen:Function}){
    const classes = useStyles();

    function Photo({ info }: { info?: Info }) {
		console.log(`info: ${info}`);
		if (!info) return null;

		return (
			<Grid container justify="center" alignItems="center" item xs={8} sm={4}>
				<Avatar
					// style={{ width: "200px", height: "200px" }}
					style={{ width: "50%", height: "50%" }}
					alt={info.name}
					src={info.photo}
				/>
			</Grid>
		);
	}

	function Name({ info }: { info?: Info }) {
		if (!info) return null;

		return (
			<Grid item>
				<Typography className={classes.name}>Aziz Akgül</Typography>
			</Grid>
		);
	}

    function Socials({ info }: { info?: Info }) {
		if (!info) return null;

		return (
			<Grid
				container
				item
				direction="row"
				spacing={3}
				justify="center"
				alignItems="center"
			>
				<Grid item>
					<a href={info.social.spotify}>
						{" "}
						<img src={spotify} alt="spotify" style={{ height: "25px" }} />
					</a>
				</Grid>

				<Grid item>
					<a href={info.social.appleMusic}>
						{" "}
						<img src={itunes} alt="itunes" style={{ height: "25px" }} />
					</a>
				</Grid>

				<Grid item>
					<a href={info.social.youtube}>
						{" "}
						<img src={youtube} alt="youtube" style={{ height: "25px" }} />
					</a>
				</Grid>

				<Grid item>
					<a href={info.social.instagram}>
						{" "}
						<img src={instagram} alt="instagram" style={{ height: "23px" }} />
					</a>
				</Grid>

				<Grid item>
					<a href={info.social.twitter}>
						{" "}
						<img src={twitter} alt="twitter" style={{ height: "26px" }} />
					</a>
				</Grid>

				<Grid item>
					<a href={info.social.soundcloud}>
						{" "}
						<img src={soundcloud} alt="soundcloud" style={{ height: "35px" }} />
					</a>
				</Grid>

				<Grid item>
					<img
						src={email}
						alt="contact"
						style={{ height: "30px" }}
						onClick={() => {
							setContactOpen();
							console.log("open contact");
						}}
					/>
				</Grid>
			</Grid>
		);
	}

    function Subscribe() {
		const iframe = `<!-- Begin Mailchimp Signup Form -->
    <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css">
    <style type="text/css">
      #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; width:100%;}
      /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
         We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
    </style>
    <div id="mc_embed_signup">
    <form action="https://tinydesign.us4.list-manage.com/subscribe/post?u=ed6adcc5c816e1973b80b059a&amp;id=bc0779255b" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div id="mc_embed_signup_scroll">
      
      <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required>
        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_ed6adcc5c816e1973b80b059a_bc0779255b" tabindex="-1" value=""></div>
        <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
        </div>
    </form>
    </div>
    
    <!--End mc_embed_signup-->`;

		return <div dangerouslySetInnerHTML={{ __html: iframe }} />;
	}

    function Bio({ info }: { info?: Info }) {
		if (!info) return null;

		return (
			<Grid
				container
				item
				direction="column"
				justify="center"
				alignItems="center"
			>
				<Grid
					container
					item
					xs={10}
					direction="column"
					justify="center"
					alignItems="center"
					spacing={3}
				>
					{info.bio.text && info.bio.text !== "" && (
						<Grid item>
							<Typography variant="body1" className={classes.bioText}>
								{info.bio.text}
							</Typography>
						</Grid>
					)}

					<Grid item>
						<Typography variant="body1" className={classes.bioText}>
							Hi, I'm Aziz Akgül
						</Typography>
					</Grid>

					<Grid item>
						<Typography variant="body1" className={classes.bioText}>
							I'm a musician and currently recording acoustic songs in my home
							studio and I release a new song every Friday.
						</Typography>
					</Grid>

					<Grid item>
						<Typography variant="body1" className={classes.bioText}>
							I also build apps. I'm the founder of{" "}
							<span>
								<a
									href="https://www.teamly.us"
									style={{ color: "#0062FF", textDecoration: "none" }}
								>
									teamly.us
								</a>
							</span>{" "}
							which automates Zoom license management for creative teams.
						</Typography>
					</Grid>

					<Grid item>
						<Typography variant="body1" className={classes.bioText}>
							Check out my spotify playlist{" "}
							<span>
								<a
									href="https://open.spotify.com/playlist/5cKE0ru8qehmmtKd7TI3oH?si=1ad3163f309e4d43"
									style={{ color: "#0062FF", textDecoration: "none" }}
								>
									Weekly Faves
								</a>
							</span>{" "}
							that I update every week.
							{/* I try to listen to around 50 new songs a day
							for inspiration and fun. My favorite ones go on this playlist. */}
						</Typography>
					</Grid>

					<Grid item>
						<Typography variant="caption" className={classes.subText}>
							Subscribe to get new song updates.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		);
	}

    return(
        <Grid
                        container
                        className={classes.root}
                        spacing={3}
                        direction="row"
                        alignItems="flex-start"
                        justify="center"
                    >
                        <Grid
                            container
                            item
                            xs={12}
                            // sm={4}
                            direction="column"
                            alignItems="center"
                            // spacing={3}
                        >
                            <Grid container item alignItems="center" justify="center">
                                <Photo info={info} />
                            </Grid>

                            <Grid item>
                                <Name info={info} />
                            </Grid>

                            <Grid item>
                                <Socials info={info} />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            item
                            xs={12}
                            // sm={8}
                            direction="column"
                            alignItems="center"
                        >
                            {/* <Grid item>
                                <Bio info={info} />
                            </Grid> */}

                            <Grid item>
                                <Typography variant="caption" className={classes.subText}>
                                    Subscribe to get new song updates.
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Subscribe />
                            </Grid>
                        </Grid>
                    </Grid>

    )
}