import React, { useState, useEffect, useRef } from "react";
import logo from "./logo.svg";
// import "./App.css";
import "../../App.css"

import firebase from "../firebase";
import * as fb from "firebase";
// import firebase from 'firebase';
import { Debugger } from "node:inspector";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
	Grid, Card, CardMedia
} from "@material-ui/core";

import SongCard from "./song"
import {Song} from "../interface"


export default function Songs({ songs }: { songs?: Song[] }) {
    if (!songs) return null;

    return (
        <Grid container   spacing={2} justify="center" style={{margin:"0px"}}>
            {songs.map((s, i) => (
                <SongCard song={s} index={i}/>             
            ))} 
        </Grid>

        
    );
}