import React, { useState, useEffect, useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
	Backdrop,
	CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
    name: {
        color: "#474747",
        fontFamily: "Roboto, sans-serif",
        // font-style: normal;
        fontWeight: "bold",
        fontSize: "33px",
        textAlign: "center",
    },
    bioText: {
        color: "#474747",
        fontFamily: "Raleway",
        // fontStyle: normal;
        fontWeight: 300,
        fontSize: "23px",
        textAlign: "center",
    },
    subText: {
        color: "#474747",
        fontFamily: "Raleway",
        // fontStyle: normal;
        fontWeight: 300,
        fontSize: "16px",
        textAlign: "center",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
})
);

export default function SimpleBackdrop({open, closeBackdrop}:{open:boolean, closeBackdrop:Function}) {
    const classes = useStyles();
    // const [open, setOpen] = useState(false);
    const handleClose = () => {
        // setOpenBackdrop(false);
        closeBackdrop()
    };
    // const handleToggle = () => {
    //     setOpen(!open);
    // };

    return (
        <Backdrop
            className={classes.backdrop}
            open={open}
            onClick={handleClose}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}